.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    color: #ff9900;
  }
  .first-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  text-align: center;
    color: white;
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background-image: url("https://firebasestorage.googleapis.com/v0/b/portfolio-website-99df9.appspot.com/o/images%2Fbackgrounds%2Fbackground.jpg?alt=media&token=7c3f6169-b45c-4c07-9284-8a24cb45c20a");
      background-color: black;
      background-size: cover;
      background-repeat: repeat;
      background-position: center;
      z-index: -1;
      transition: transform 1s;
      transition-delay: 0.5s;
      animation: zoom-in 2s;
      animation-delay: 0.5s;
      animation-fill-mode: both;
      @media screen and (orientation:portrait) {
        width: 105vh;
        height: 105vw;
        transform: rotate(90deg);
        animation: zoom-in-portrait 2s;
        animation-delay: 0.5s;
        animation-fill-mode: both;
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50vh;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 50%, #000000 99%);
    };
  }

  .video-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    .text {
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      transform: translateY(-40%);
      color: white;
      text-align: center;
    }
    .content {
      width: 100%;
      max-width: 900px;
      max-height: 500px;
      .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        width: 100%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

  }
  .container {
    width: 100%;
    padding: 5px;
    @media (max-width: 1299px) {
      padding: 0 5px 0 5px;
    }
  }
}

@keyframes zoom-in {
  from {transform: scale(1);}
  to {transform: scale(1.1);}
}

@keyframes zoom-in-portrait {
  from {transform: scale(1) rotate(90deg);}
  to {transform: scale(1.1) rotate(90deg);}
}

