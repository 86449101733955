.fullscreen-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  animation-name: fade-in;
  animation-duration: 0.5s;
  z-index: 1000;
  #fullscreen-viewer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .loading-view {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50vw;
      height: 50vh;
    }
    img {
      min-width: 70%;
      max-width: 100%;
      max-height: 70vh;
      cursor: pointer;
      user-select: none;
      &::selection {
        background-color: transparent;
      }
    }
    .info-box {
      display: flex;
      align-items: center;
      margin-top: 5px;
      color: white;
      h2 {
        margin-left: 5px;
      }
    }
  }
  .arrow {
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    opacity: 0.7;
    cursor: pointer;
    .arrow-icon {
      color: #ffffff;
    }
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
    &.forward-arrow {
      justify-content: end;
    }
  }
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 100;}
}
