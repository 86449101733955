$grid-margin: 5px;

.portfolio-title {
  margin-top: 30px !important;
}

.photo-grid {
  width: 100%;
  .loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .row {
    width: 100%;
    margin-top:$grid-margin;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    img {
      cursor: pointer;
      height: 100%;
      margin-right: $grid-margin;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .last-class {
    justify-content: left;
    img {
      margin-left: $grid-margin;
      margin-right: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.lazy {
  image-rendering: pixelated;
  filter: blur(2px);
}
